import styled from 'styled-components';
import { NPS, Modal } from '@whitelabel/component-library';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledNPS = styled(NPS)`
  .alert {
    margin-bottom: 0;
  }
  h1 {
    ${fontStyle('h3')};
    text-transform: uppercase;
    margin-bottom: 0;
  }
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 1.5rem;
    ${mediaBreakpointUp('sm')`
      padding: 2rem;
    `}
  }
`;
