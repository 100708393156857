import { useRouter } from 'next/router';
import {
  SOURCE_EMAIL,
  SOURCE_SMS,
  NPS_EMAIL_MODAL_PARAMS,
  NPS_SMS_MODAL_PARAMS,
} from '@whitelabel/xcover-shared/helpers/constants';
import { INPSQueryParams } from '@whitelabel/helpers/types';
import { IFeedbackQueryParams } from '@whitelabel/xcover-shared/helpers/types';

const WIDGET_MODAL_PARAMS = {
  SECURITY_TOKEN: 'security_token',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  RATING: 'rating',
  BOOKING_ID: 'bookingID',
  PARTNER_ID: 'partner_id',
};

interface IUseWidgetOnHome {
  feedbackQueryParams: IFeedbackQueryParams | undefined;
}

export function useFeedbackWidgetOnHome(): IUseWidgetOnHome {
  const { query } = useRouter();
  const paramsArray = Object.values(WIDGET_MODAL_PARAMS).filter((value) => value !== WIDGET_MODAL_PARAMS.RATING);

  const queryComplete = paramsArray.every((item: string) => !!query[item]);

  const feedbackQueryParams = queryComplete
    ? {
        securityToken: query[WIDGET_MODAL_PARAMS.SECURITY_TOKEN],
        email: query[WIDGET_MODAL_PARAMS.EMAIL],
        firstName: query[WIDGET_MODAL_PARAMS.FIRST_NAME],
        lastName: query[WIDGET_MODAL_PARAMS.LAST_NAME],
        bookingID: query[WIDGET_MODAL_PARAMS.BOOKING_ID],
        partnerID: query[WIDGET_MODAL_PARAMS.PARTNER_ID],
        rating: parseInt((query[WIDGET_MODAL_PARAMS.RATING] as string) || '0', 10),
      }
    : undefined;

  return {
    feedbackQueryParams: feedbackQueryParams as IFeedbackQueryParams,
  };
}

export const useNPSQueryParams = () => {
  const { query } = useRouter();
  if (query[NPS_EMAIL_MODAL_PARAMS.NPS_SOURCE] === SOURCE_EMAIL) {
    return {
      npsSource: query[NPS_EMAIL_MODAL_PARAMS.NPS_SOURCE],
      source: query[NPS_EMAIL_MODAL_PARAMS.SOURCE],
      bookingID: query[NPS_EMAIL_MODAL_PARAMS.BOOKING_ID],
      score: query[NPS_EMAIL_MODAL_PARAMS.SCORE],
    } as INPSQueryParams;
  }

  if (query[NPS_SMS_MODAL_PARAMS.NPS_SOURCE] === SOURCE_SMS) {
    return {
      npsSource: query[NPS_SMS_MODAL_PARAMS.NPS_SOURCE],
      source: query[NPS_SMS_MODAL_PARAMS.SOURCE],
      bookingID: query[NPS_SMS_MODAL_PARAMS.BOOKING_ID],
    } as INPSQueryParams;
  }

  return undefined;
};
