import styled from 'styled-components';
import Layout from '../components/Layout';

export const StyledLayout = styled(Layout)`
  > div {
    min-height: 100vh;
  }
`;

export default StyledLayout;
