import React from 'react';
import { withTheme, Theme } from 'styled-components';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { locales } from '@whitelabel/helpers/locale';
import { IPageMeta } from '@whitelabel/helpers/types';
import { PROTOCOL_DOMAIN } from '@whitelabel/helpers/url';
import { SITE_NAME } from '../../helpers/constants';

interface IPageMetaProps extends IPageMeta {
  theme: Theme;
}

const PageMeta = ({
  title,
  seoTitle,
  searchDescription,
  socialTitle,
  socialDescription,
  socialImage,
  ogType,
  theme,
  script,
}: IPageMetaProps) => {
  const { locale, pathname } = useRouter();

  return (
    <Head>
      <title>{seoTitle || title ? `${seoTitle || title} | ${SITE_NAME}` : SITE_NAME}</title>
      {searchDescription && <meta name="description" content={searchDescription} />}

      <link rel="mask-icon" href="/static/safari-pinned-tab.svg" color={theme.themeColors.primary} />
      <meta name="theme-color" content={theme.themeColors.primary} />

      <meta property="og:url" content={`${PROTOCOL_DOMAIN}/${locale}${pathname === '/' ? '' : pathname}`} />
      {(socialTitle || seoTitle || title) && <meta property="og:title" content={socialTitle || seoTitle || title} />}
      {(socialDescription || searchDescription) && (
        <meta property="og:description" content={socialDescription || searchDescription} />
      )}
      {socialImage && <meta property="og:image" content={socialImage} />}
      {ogType && <meta property="og:type" content={ogType} />}
      <link rel="canonical" href={`${PROTOCOL_DOMAIN}/${locale}${pathname === '/' ? '' : pathname}`} />

      {Object.values(locales).map(({ code }) => (
        <link
          key={code}
          rel="alternate"
          href={`${PROTOCOL_DOMAIN}/${code}${pathname === '/' ? '' : pathname}`}
          hrefLang={code}
        />
      ))}

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          url: PROTOCOL_DOMAIN,
          name: SITE_NAME,
        })}
      </script>

      {
        script &&
          script.map((props: any) => <script key={props.src} {...props} />) /* eslint-disable-line react/prop-types */
      }
    </Head>
  );
};

export default withTheme(PageMeta);
