import React from 'react';
import { GetServerSideProps } from 'next';
import { ContentBlock } from '@whitelabel/component-library';
import { IPage } from '@whitelabel/helpers/types';
import api from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { PUBLIC_NAV_ITEMS, SOURCE_EMAIL } from '@whitelabel/xcover-shared/helpers/constants';
import PageMeta from '@whitelabel/xcover-shared/components/PageMeta';
import useShowCookieModal from '@whitelabel/xcover-www-frontend/src/helpers/useShowCookieModal';
import NPSfeedbackModal from '@whitelabel/xcover-www-frontend/src/components/NPSfeedbackModal';
import ErrorPage from '../components/ErrorPage';
import NavBar from '../components/NavBar';
import { StyledLayout } from '../styled/StyledLayout';
import { useNPSQueryParams } from '../pageFiles/homepage/helper';
import { getRequestIDInit } from '../../logger';
import { setErrorResponse, setSuccessResponse } from '../helpers/serverResponse';

interface IHomeProps {
  page?: IPage;
}

export const Homepage = ({ page }: IHomeProps): JSX.Element => {
  const npsQueryParams = useNPSQueryParams();
  const isCookieModalOpen = useShowCookieModal();
  if (!page) {
    return <ErrorPage />;
  }

  return (
    <StyledLayout navBar={<NavBar items={PUBLIC_NAV_ITEMS} size="lg" />} spacing="lg">
      {page && (
        <>
          <PageMeta
            title={page.title}
            seoTitle={page.meta.seoTitle}
            searchDescription={page.meta.searchDescription}
            socialTitle={page.meta.socialTitle}
            socialDescription={page.meta.socialDescription}
            socialImage={page.meta.socialImage}
            ogType={page.meta.ogType}
          />
          <ContentBlock blocks={page.body} />
          {!isCookieModalOpen && npsQueryParams && (
            <NPSfeedbackModal
              score={npsQueryParams.npsSource === SOURCE_EMAIL ? npsQueryParams.score : undefined}
              source={npsQueryParams.source}
              bookingID={npsQueryParams.bookingID}
            />
          )}
        </>
      )}
    </StyledLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale, req, res }) => {
  const slug = 'home';
  let page;
  try {
    page = await api.get(`${getAPIHost()}/staticpages/${slug}/?language=${locale}`, false, getRequestIDInit(req));
    setSuccessResponse(res);
  } catch (err) {
    page = null;
    setErrorResponse(res, err);
  }
  return { props: { page } };
};

export default Homepage;
